import React from "react";
import "toastr/build/toastr.min.css";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Delete from "../../common/Dialogs/Delete";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TestService from "../../Services/TestService";
import UserService from "../../Services/UserService";
import { es } from "date-fns/locale";
import GeneralFunctions from "../../libs/GeneralFunctions";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const SIZE_PAGE = 5;

class ResetTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      page: 1,
      trivias: [],
      users: [],
      id_trivia: false,
      id_user: false,
      label_trivia: this.props.t("search-trivia", "Search Trivia"),
      label_user: this.props.t("search-user", "Buscar Usuario"),
    };
  }

  componentDidMount() {
    TestService.getAllTests(1, 99999)
      .then((response) => {
        const trivias = [];
        response.data.content.forEach((elem) => {
          trivias.push({
            value: elem.id,
            label: elem.name,
          });
        });
        this.setState({ trivias: trivias });
      })
      .catch(($error) => {
        console.log("Ha ocurrido un error", $error);
      });
    UserService.getUserAllCompany()
      .then((response) => {
        const users = response.data.content.map((i) => {
          return {
            value: i.id,
            label: `${i.name} ${i.lastName}`,
          };
        });
        this.setState({ users: users });
      })
      .catch(($error) => {
        console.log("Ha ocurrido un error", $error);
      });
    this.getTest();
  }

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.getTest();
  }

  selectTrivia({ value, label }) {
    this.setState({ id_trivia: value });
    this.setState({ label_trivia: label });
    this.setState({ page: 1 });
    this.getTest(this.state.page, SIZE_PAGE, value);
  }

  selectUser({ value, label }) {
    this.setState({ id_user: value });
    this.setState({ label_user: label });
    this.setState({ page: 1 });
    this.getTest(this.state.page, SIZE_PAGE, this.state.id_trivia, value);
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getTest(value);
  }

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.getTest();
  }

  getTest(
    number = 1,
    size = SIZE_PAGE,
    id = this.state.id_trivia,
    user_id = this.state.id_user
  ) {
    function createData(id, trivia, user, created) {
      return {
        id,
        trivia,
        user,
        created,
      };
    }

    this.setState({ loading: true });
    TestService.getTestsForReset(number, size, id, user_id)
      .then((response) => {
        this.setState({ rows: [] });
        let rowTests = [];
        response.data.content.forEach((test) =>
          rowTests.push(
            createData(
              test.id,
              test.trivia,
              test.user,
              GeneralFunctions.formatNewDate(test.created, es)
            )
          )
        );
        this.setState({ rows: rowTests });
        this.setState({ numberPages: response.data.totalPages });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-get-tests",
            "An error occurred while trying to get the trivia"
          )
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  deleteTest(id) {
    this.setState({ loading: true });
    TestService.deleteTestForReset(id)
      .then(() => {
        toastr.success(
          this.props.t(
            "menu.trivia-panel-message-success-reset-test",
            "Trivia rebooted successfully"
          )
        );
        this.getTest(this.state.page, SIZE_PAGE, this.state.id_trivia);
        if (this.state.rows.length - 1 === 0) {
          this.setState({
            page: this.state.page - 1 === 0 ? 1 : this.state.page - 1,
          });
        }
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-delete-test-reset",
            "This trivia score cannot be removed"
          )
        );

        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, rows, numberPages, page, trivias, users } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows ? (
              <Grid container spacing={1}>
                <Link to="/test/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-dialog-test-question-button-back",
                      "Back"
                    )}
                  </Button>
                </Link>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t("menu.rigth-reset-trivia", "Reset trivia")}
                </Button>

                <Select
                  options={trivias}
                  placeholder={
                    <div>
                      {this.state.label_trivia.length > 14
                        ? this.state.label_trivia.slice(0, 14) + "..."
                        : this.state.label_trivia}
                    </div>
                  }
                  value={this.state.id_trivia}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "200px",
                      height: "55px",
                      marginBottom: "8px",
                      marginRight: "8px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "0.7rem",
                    }),
                  }}
                  onChange={(option) => this.selectTrivia(option)}
                />

                <Select
                  options={users}
                  placeholder={
                    <div>
                      {this.state.label_user.length > 14
                        ? this.state.label_user.slice(0, 14) + "..."
                        : this.state.label_user}
                    </div>
                  }
                  value={this.state.id_user}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "200px",
                      height: "55px",
                      marginBottom: "8px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "0.7rem",
                    }),
                  }}
                  onChange={(option) => this.selectUser(option)}
                />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.rigth-list-item-trivia", "Trivia")}
                        </TableCell>
                        <TableCell align="left">
                          {t("name-surname.label", "Name and surname")}
                        </TableCell>
                        <TableCell align="left">
                          {t("creation-date.label", "Creado")}
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.trivia.name}</TableCell>

                          <TableCell align="left">
                            {row.user.name} {row.user.lastName}
                          </TableCell>
                          <TableCell align="left">{row.created}</TableCell>

                          <TableCell align="left">
                            <Delete
                              title={t(
                                "menu.rigth-reset-trivia",
                                "Reset trivia"
                              )}
                              titleButton={t(
                                "menu.rigth-reset-trivia",
                                "Reset trivia"
                              )}
                              message={t(
                                "admin.dialog-reset-trivia-text",
                                "Are you sure to reset the trivia?"
                              )}
                              deleteMetric={() => this.deleteTest(row.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(ResetTest)
);
