import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "../../components/Button";
import error from "../../assets/img/close.svg";
import success from "../../assets/img/checked-3.svg";
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import sopa_de_letras_1 from "../../assets/img/sopa-de-letras-1.png";
import sopa_de_letras_2 from "../../assets/img/sopa-de-letras-2.png";
import sopa_de_letras_3 from "../../assets/img/sopa-de-letras-3.png";
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import LetterSoupServices from "../../Services/LetterSoup/Global";
import EmptyMessage from "../../components/EmptyMessage";
import environment from "../../libs/environment";
import PeopleIcon from '@material-ui/icons/People';

import "./styles.scss";
import "toastr/build/toastr.min.css";

const LetterSoupCart = (props) => {
  const [showSparks, setShowSparks] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (showSparks) {
      let timer = setTimeout(() => setShowSparks(false), 5000);

      return () => { clearInterval(timer) };
    }
  }, [showSparks])

  const extractWordOfRulesJson = (cluesString) => {
    let clues = JSON.parse(cluesString); 
    if(clues.clues.length){
       let pal = [];
       let auxwords=clues.clues;
       auxwords.forEach(element => {
         pal.push(element.word);
      });

      return pal.join(', ');       
    }
    return;
  }

  const extractCluesOfRulesJson = (cluesString) => {
    let clues = JSON.parse(cluesString); 
    if(clues.clues.length){
       let pal = [];
       let auxwords=clues.clues;
       auxwords.forEach(element => {
         pal.push(element.clue);
      });

      return pal.join(', ');       
    }
    return;
 }
  
  return (
    <div className="card-lettersoup card-model">
      <>
        <Grid xs={12} md={2} xl={2} className="card-lettersoup-image-container">
          <div className="lettersoup-image-wrapper">
            <span
              className="lettersoup-image"
              style={{
                backgroundImage: `url(${props.image != '' ? `${environment.motivarnosBackend}${props.image}` : ((Math.floor(Math.random() * 3) + 1) == 1 ? sopa_de_letras_1 : ((Math.floor(Math.random() * 3) + 1) == 2 ? sopa_de_letras_2 : sopa_de_letras_3))})`,
              }}
            ></span>
          </div>
        </Grid>

        <Grid xs={12} lg={5} className="card-lettersoup-info-container">
          <div className="info-container-type"></div>
          <div className="info-container-title">
            {props.title.charAt(0).toUpperCase() + props.title.slice(1)}
          </div>
          <div className="info-container-subtitle">
            {props.description}
          </div>
          <div className="info-container-state">
            <ErrorOutlineIcon
              style={{
                fill: "rgb(158, 158, 158)",
                width: "20px",
                height: "20px",
                marginRight: "2px",
              }}
            />
            {`${!props.isExpired? 'Finaliza': 'Finalizó'} el ${format(new Date(props.date), "dd-MM-yyyy")}`}

            {(props.secondary == true || props.isExpired) && props.data.timeInSec != null && props.data.timeInSec != 0 && <><AccessAlarmsIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>{props.data.timeInSec} segundos</>}

            {props.secondary == false && props.data.timeInSecUsed != null && props.data.timeInSecUsed != 0 && <><AccessAlarmsIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>{props.data.timeInSecUsed} segundos usados</>}

            {props.secondary ? (
              <>
                <NotificationImportantIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>
                Pendiente
              </>
            ) : props.isExpired?(
              
              <>
                <FolderSpecialIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>
                Vencida
              </>
            ):(
              
              <>
                <FolderSpecialIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>
                Realizado
              </>
            )}

          <PeopleIcon
              style={{
                fill: "rgb(158, 158, 158)",
                width: "20px",
                height: "20px",
                marginRight: "2px",
                marginLeft: "6px",
              }}
            />
            Participantes {props.participantesQty}
          </div>
        </Grid>

        <Grid xs={4} lg={7} className="card-activity-winers">
          {(props.data.gameWords!==null && props.data.gameWords!=='') ?                  
            <span className="resumen-min-item exam">
              <p>Palabras</p>
              <div key="worklistresum" className="lettersoup-cart-wrapper Wordsearch-row">
               {props.data.gameWords}
              </div>
            </span>                  
          :
           <span className="lettersoup-wrapper exam">
             <p>{t("menu.trivia-panel-dialog-test-question-table-clue", "Clue")+'s'}</p>
             <div key="clueslistresum" className="lettersoup-cart-wrapper">
              {props.data.gameRules !==null ? extractCluesOfRulesJson(props.data.gameRules) : ""}
             </div>
             <p>{t("admin.words", "Words")}</p>
             <div key="wordlistresum" className="lettersoup-cart-wrapper">                     
              {props.data.gameRules !==null ? extractWordOfRulesJson(props.data.gameRules): ""}
             </div>
            </span>
          }
        </Grid>

        <Grid xs={12} lg={3} className="card-activity-button-container">
          {props.secondary ? (
            <NavLink to={`/soup/play/${props.id}/${props.userId}`}>
              <Button className="button-activity">
                Participar{" "}
                <SportsEsportsIcon
                  style={{ fill: "white", marginLeft: "8px" }}
                />
              </Button>
            </NavLink>
          ) : (
            <>
              {!props.data.isWin ? (
                <a>
                  <Button className="button-error">
                    {props.data.points}
                    <img src={error} alt="" />
                  </Button>
                </a>
              ) : (
                <a>
                  <Button className="button-success">
                    {props.data.points}
                    <img src={success} alt="" />
                  </Button>
                </a>
              )}

              {/*<NavLink to={`/soup/play/${props.id}/${props.userId}`}>
                  <Button className="button-error">
                    {props.data.points}
                    <img src={error} alt="" />
                  </Button>
              </NavLink>*/}
            </>
          )}
        </Grid>
      </>
    </div>
  );
};

LetterSoupCart.defaultProps = {
  title: "",
  name: "",
  badge: null,
  btnText: "",
};

const LetterSoup = () => {
  const [lettersoup, setLetterSoup] = useState(null);
  const { t } = useTranslation();
  const [sesion, setSesion] = useState(null);

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));
    LetterSoupServices.findAll()
      .then((resp) => {
        setLetterSoup(resp.data.content);      
      })
      .catch(() => {});
    LetterSoupServices.getUser(sesion.user.id)
      .then((resp) => {
        sesion = { ...sesion, user: resp.data };
        setSesion(sesion);
      })
      .catch(() => {});

    setSesion(sesion);
  }, []);

  function replaceAll(string, search, replace) {
    if (string === null) return "";
    return string.split(search).join(replace).split(" ")[0];
  }

  if (sesion)
    return (
      <div className="lettersoup-wrapper">
        <div className="lettersoup-container">
          <div className="lettersoup-title">{t("menu.rigth-list-item-lettersoup")}</div>
          {lettersoup === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            lettersoup.map((data, index) => {
              let regex = /T(.)*/;
              let date = replaceAll(data.expirationDate, "-", "/").replace(regex, "");
            
              return (
                <LetterSoupCart
                  key={index}
                  badge={data.badge}
                  title={data.gameName}
                  description={data.gameDescription}
                  btnText={
                    !data.userScoreId
                      ? t("play.label", "Play")
                      : t("summary.label", "Summary")
                  }
                  image={data.image}
                  secondary={!data.userScoreId && !data.isExpired}
                  isExpired={!data.userScoreId && data.isExpired}
                  id={parseInt(data.id)}
                  userId={parseInt(data.user.id)}
                  time={parseInt(data.timeInSec)}
                  participantesQty={data.participantesQty}
                  points={data.points}
                  date={date}
                  data={data}
                  score={data.userScoreId}
                />
              );
            })
          )}
          {lettersoup !== null ? lettersoup.length === 0 && <EmptyMessage /> : ""}
        </div>
      </div>
    );
  else return "";
};

export default LetterSoup;
