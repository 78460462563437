import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link } from "react-router-dom";

/********Icons******** */
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import metrics from "../../../../assets/img/speedometer.svg";
import badge from "../../../../assets/img/badge.svg";
import prize from "../../../../assets/img/prize.svg";
import trivia from "../../../../assets/img/quiz.svg";
import shareFileIcon from "../../../../assets/img/link.svg";
import soup from "../../../../assets/img/soup.svg";
import users from "../../../../assets/img/group.svg";
import ranking from "../../../../assets/img/ranking.svg";
import apuestas from "../../../../assets/img/apuestas.svg";
import goal from "../../../../assets/img/goal.svg";
import mission from "../../../../assets/img/mission.svg";
import battle from "../../../../assets/img/battle.svg";
import megaphone from "../../../../assets/img/megaphone.svg";
import groups from "../../../../assets/img/groups.svg";
import secondarygroups from "../../../../assets/img/secondarygroups.svg";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";

export default function MainListItems({ open }) {
  const { t } = useTranslation();

  return (
    <div>
      <Link to="/admin">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-dashboard", "Dashboard")}
              className="doneList"
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-dashboard", "Dashboard")}>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-dashboard", "Dashboard")}
                className="doneList"
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/userPanel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={users} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-users", "Users")}
              className="doneList"
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-users", "Users")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={users} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-users", "Users")}
                className="doneList"
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/groups">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={groups} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.user-panel-button-groups", "Group")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.user-panel-button-groups", "Group")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={groups} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.user-panel-button-groups", "Group")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

     {/*  <Link to="/secondarygroups">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={secondarygroups} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t(
                "menu.user-panel-button-secondary-groups",
                "Secondary Teams"
              )}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip
            title={t(
              "menu.user-panel-button-secondary-groups",
              "Secondary Teams"
            )}
          >
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={secondarygroups} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "menu.user-panel-button-secondary-groups",
                  "Secondary Teams"
                )}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link> */}

      <Link to="/sharefile">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={shareFileIcon} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.sharefile", "Archivo")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.sharefile", "Archivo")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={shareFileIcon} alt="Archivo" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.sharefile", "Archivo")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/test/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={trivia} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-trivia", "Trivia")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-trivia", "Trivia")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={trivia} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-trivia", "Trivia")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/letter/soup">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={soup} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText primary={"Sopa de letra"} />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={"Sopa de letra"}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={soup} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText primary={"Sopa de letra"} />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/badges/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={badge} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-badges", "Badges")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-badges", "Badges")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={badge} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-badges", "Badges")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/metrics/panel">
        {open && (
          <ListItem>
            <ListItemIcon>
              <span className="icon">
                <img src={metrics} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-metrics", "Metrics")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-metrics", "Metrics")}>
            <ListItem>
              <ListItemIcon>
                <span className="icon">
                  <img src={metrics} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-metrics", "Metrics")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/missions/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={mission} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-missions", "Missions")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-missions", "Missions")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={mission} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-missions", "Missions")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/challenges/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={goal} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-challenges", "Challenges")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-challenges", "Challenges")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={goal} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-challenges", "Challenges")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/bets/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={apuestas} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText primary={t("menu.rigth-list-item-bets", "Bets")} />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-bets", "Bets")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={apuestas} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText primary={t("menu.rigth-list-item-bets", "Bets")} />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/tournament/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={battle} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-tournaments", "Tournaments")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-tournaments", "Tournaments")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={battle} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-tournaments", "Tournaments")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/ranking/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={ranking} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.award-category", "Award Category")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.award-category", "Award Category")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={ranking} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.award-category", "Award Category")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/hall-fame">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("hall-of-fame", "Hall of Fame")}
              className="doneList"
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("hall-of-fame", "Hall of Fame")}>
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("hall-of-fame", "Hall of Fame")}
                className="doneList"
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/awards/panel">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={prize} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t("menu.rigth-list-item-awards", "Awards")}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-awards", "Awards")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={prize} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t("menu.rigth-list-item-awards", "Awards")}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/news">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={megaphone} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText primary={t("menu.rigth-list-item-news", "News")} />
          </ListItem>
        )}

        {!open && (
          <Tooltip title={t("menu.rigth-list-item-news", "News")}>
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={megaphone} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText primary={t("menu.rigth-list-item-news", "News")} />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/report/points-coins">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={ranking} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t(
                "menu.rigth-list-item-report-coins",
                "Reporte de transacciones"
              )}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip
            title={t("menu.rigth-list-item-report-coins", "Reporte de transacciones")}
          >
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={ranking} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "menu.rigth-list-item-report-coins",
                  "Reporte de transacciones"
                )}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/report/feelings">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={ranking} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t(
                "menu.rigth-list-item-report-feelings",
                "Rep. feelings"
              )}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip
            title={t("menu.rigth-list-item-report-feelings", "Rep. feelings")}
          >
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={ranking} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "menu.rigth-list-item-report-feelings",
                  "Rep. feelings"
                )}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/report/notifications-third">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={ranking} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t(
                "menu.rigth-list-item-report-notifications",
                "Rep. notifications/third-party"
              )}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip
            title={t(
              "menu.rigth-list-item-report-notifications",
              "Rep. notifications/third-party"
            )}
          >
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={ranking} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "menu.rigth-list-item-report-notifications",
                  "Rep. notifications/third-party"
                )}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>

      <Link to="/suggestion-inbox">
        {open && (
          <ListItem button>
            <ListItemIcon>
              <span className="icon">
                <img src={trivia} alt="" />
              </span>
            </ListItemIcon>
            <ListItemText
              primary={t(
                "menu.rigth-list-item-suggestion-inbox",
                "Suggestions"
              )}
            />
          </ListItem>
        )}

        {!open && (
          <Tooltip
            title={t(
              "menu.rigth-list-item-suggestion-inbox",
              "Suggestions"
            )}
          >
            <ListItem button>
              <ListItemIcon>
                <span className="icon">
                  <img src={trivia} alt="" />
                </span>
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "menu.rigth-list-item-suggestion-inbox",
                  "Suggestions"
                )}
              />
            </ListItem>
          </Tooltip>
        )}
      </Link>
    </div>
  );
}
