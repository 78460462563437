import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import { es } from "date-fns/locale";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MissionsService from "../../Services/MissionsService";
import ReportButton from "./ReportButton";
import toastr from "toastr";
import {
  teamsRequest,
  usersRequest,
  secondaryTeamsRequest,
  imagesRequest,
} from "../../../../../actions/generalActions";
import {
  getMissionsRequest,
  deleteMissionRequest,
  saveMissionRequest,
  updateMissionRequest,
} from "../../../../../actions/missionsActions";
import MissionDetail from "./MisionUser";
import ViewListIcon from "@material-ui/icons/ViewList";
import NewMissionDialog from "./NewMissionDialog";
import GeneralFunctions from "../../libs/GeneralFunctions";
import GameResets from "../../common/Dialogs/GameResets";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingTop: "55px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },

  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  input: {
    display: "none",
  },
}));

function Missions(props) {
  const [openNewMission, setOpenNewMission] = useState(false);
  const [openEditMission, setOpenEditMission] = useState(false);
  const [loadingEditData, setLoadingEditData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [editData, setEditData] = useState(null);
  const [missionState, setMissionState] = useState({ open: false });

  const { t } = useTranslation();

  useEffect(() => {
    if (!props.generalReducer.users) {
      props.usersRequest();
    }

    if (!props.generalReducer.images) {
      props.imagesRequest();
    }

    if (!props.generalReducer.teams) {
      props.teamsRequest();
    }
    if (!props.generalReducer.secondary_teams) {
      props.secondaryTeamsRequest();
    }

    props.getMissionsRequest(1);

    // eslint-disable-next-line
  }, []);


  const handleChange = (event, value) => {
    setPage(value);
    props.getMissionsRequest(value + 1);
  };

  const classes = useStyles();

  const handleOpenNewMission = () => {
    setOpenNewMission(!openNewMission);
  };

  const handleOpenEditMission = (value) => {
    setLoadingEditData(true);
    MissionsService.getMission(value.id)
      .then((response) => {
        setEditData(response.data);
        setLoadingEditData(false);
      })
      .catch(() => {
        toastr.error("Ocurrio un error al cargar los datos de la mision");
        setLoadingEditData(false);
      });
    setOpenEditMission(!openEditMission);
  };

  const handleCloseEditMission = () => {
    setOpenEditMission(!openEditMission);
    setTimeout(() => {
      setEditData(null);
    }, 250);
  };

  const deleteMission = async (id) => {
    let status;
    await props.deleteMissionRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(t("mission.removed", "Mission removed successfully"));
      return;
    }

    if (status.error && status.error.status === 422) {
      toastr.error(
        t(
          "mission-used.removed",
          "This mission is being used and cannot be removed"
        )
      );
    } else {
      toastr.error(
        t(
          "mission-used-error.removed",
          "An error occurred while deleting the mission"
        )
      );
    }
  };

  const handleOpenMissionDetail = (data) => {
    setMissionState({ ...missionState, open: !missionState.open, data });
  };

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer}>
        <Container maxWidth="lg" className={classes.container}>
          {(props.missionsReducer.missions_charging ||
            props.generalReducer.teams_charging ||
            props.generalReducer.secondary_teams_charging ||
            loadingEditData ||
            props.generalReducer.images_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!props.missionsReducer.missions_charging &&
            !props.generalReducer.teams_charging &&
            !props.generalReducer.secondary_teams_charging &&
            !props.generalReducer.images_charging &&
            (props.missionsReducer.missions &&
            props.missionsReducer.missions.length > 0 ? (
              <Grid container spacing={1}>
                <NewMissionDialog
                  teams={props.generalReducer.teams.content}
                  rowSecondaryTeams={
                    props.generalReducer.secondary_teams.content
                  }
                  rowsUsers={props.generalReducer.users.content}
                  open={openNewMission}
                  title={t("admin.mission-new-mission", "New mission")}
                  data={null}
                  firstImage={props.generalReducer.images.content[0].url}
                  onClose={handleOpenNewMission}
                  saveMissionRequest={(data) => props.saveMissionRequest(data)}
                  updateMissionRequest={(data) =>
                    props.updateMissionRequest(data)
                  }
                />
                <Link className="link-custom" to="/report/votations" style={{display: 'none'}}>
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("voting-missions", "Voting in Missions")}
                  </Button>
                </Link>
                
                <ReportButton/>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.points-coins-table-mission", "Mission")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.badge-report-message-table", "Message")}
                        </TableCell>

                        <TableCell align="left">
                          {t("creation-date.label", "Creation date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("date-from.label", "Start date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("expiration-date.label", "Expiration date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("guests-inhouse", "Guests InHouse")}
                        </TableCell>

                        
                        <TableCell align="left">
                          Participantes Inhouse
                        </TableCell>

                        <TableCell align="left">
                          {t("guests-bpo", "Guests BPO")}
                        </TableCell>
                        
                        <TableCell align="left">
                          Participantes BPO
                        </TableCell>

                        <TableCell align="left">
                          {t("guests", "Guests")}
                        </TableCell>

                        <TableCell align="left">
                          {t("completed-missions", "Misiones Completadas")}
                        </TableCell>

                        <TableCell align="left">
                          {t("hide-date.label", "Hide date")}
                        </TableCell>

                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.missionsReducer.missions
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => {
                          let created = GeneralFunctions.formatNewDate(
                            data.created,
                            es
                          );

                          let expirationDate = GeneralFunctions.formatNewDate(
                            data.expirationDate,
                            es
                          );

                          let hideOnDate = GeneralFunctions.formatNewDate(
                            data.hideOnDate,
                            es
                          );

                          let dateFrom = GeneralFunctions.formatNewDate(
                            data.dateFrom,
                            es
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="left"
                                className="pointer"
                                onClick={() => handleOpenMissionDetail(data)}
                              >
                                {data.name !== null ? data.name : ""}
                              </TableCell>

                              <TableCell align="left">
                                {data.description !== null
                                  ? data.description.length > 30
                                    ? data.description.substring(0, 30) + "..."
                                    : data.description
                                  : ""}
                              </TableCell>
                              <TableCell align="left">{created}</TableCell>
                              <TableCell align="left">{dateFrom}</TableCell>
                              <TableCell align="left">
                                {expirationDate}
                              </TableCell>
                              <TableCell align="left">
                                {data.invitedInhouseCount
                                  ? data.invitedInhouseCount
                                  : 0}
                              </TableCell>
                              
                              <TableCell align="left">
                                {data.participantesInhouse
                                  ? data.participantesInhouse
                                  : 0}
                              </TableCell>

                              <TableCell align="left">
                                {data.invitedOutsourceCount
                                  ? data.invitedOutsourceCount
                                  : 0}
                              </TableCell>                              
                              
                              <TableCell align="left">
                                {data.participantesBPO
                                  ? data.participantesBPO
                                  : 0}
                              </TableCell>

                              <TableCell align="left">
                                {data.invitedCount ? data.invitedCount : 0}
                              </TableCell>
                              <TableCell align="left">
                                {data.completedQty ? data.completedQty : 0}
                              </TableCell>
                              <TableCell align="left">{hideOnDate}</TableCell>
                              <TableCell align="left">{data.points}</TableCell>
                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="bet-crud"
                                >
                                  <span
                                    className="crud-icon pointer crud-icon-delete"
                                    onClick={() => {
                                      handleOpenEditMission(data);
                                    }}
                                  >
                                    <EditIcon />
                                  </span>

                                  <Tooltip
                                    title={t("admin.actions-delete", "Delete")}
                                  >
                                    <span
                                      className="crud-icon pointer"
                                      onClick={() => {
                                        deleteMission(data.id);
                                      }}
                                      style={{
                                        filter: "saturate(1)",
                                      }}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  </Tooltip>
                                  
                                  <GameResets game_id={data.id} gameType={"mission"}/>

                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={props.missionsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) => handleChange(event, value)}
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NewMissionDialog
                      teams={props.generalReducer.teams.content}
                      rowSecondaryTeams={
                        props.generalReducer.secondary_teams.content
                      }
                      firstImage={
                        props.generalReducer.images
                          ? props.generalReducer.images.content[0].url
                          : ""
                      }
                      rowsUsers={props.generalReducer.users.content}
                      open={openNewMission}
                      title={t("admin.mission-new-mission", "New mission")}
                      data={null}
                      onClose={handleOpenNewMission}
                      saveMissionRequest={(data) =>
                        props.saveMissionRequest(data)
                      }
                      updateMissionRequest={(data) =>
                        props.updateMissionRequest(data)
                      }
                    />

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}

          {(editData !== null) ? (
            <NewMissionDialog
              teams={props.generalReducer.teams.content}
              rowSecondaryTeams={props.generalReducer.secondary_teams.content}
              firstImage={props.generalReducer.images.content[0].url}
              rowsUsers={props.generalReducer.users.content}
              open={openEditMission}
              onClose={handleCloseEditMission}
              title={t("admin.mission-edit-mission", "Edit mission")}
              data={editData}
              saveMissionRequest={(data) => props.saveMissionRequest(data)}
              updateMissionRequest={(data) => props.updateMissionRequest(data)}
            />
          ) : (
            ""
          )}
          <MissionDetail
            title={t("menu.metric-panel-dialog-show-detail", "Show details")}
            open={missionState.open}
            onClose={handleOpenMissionDetail}
            data={missionState.data}
          />
        </Container>
      </div>
    </main>
  );
}

const mapStateToProps = ({ generalReducer, missionsReducer }) => ({
  generalReducer,
  missionsReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  secondaryTeamsRequest,
  imagesRequest,
  getMissionsRequest,
  deleteMissionRequest,
  saveMissionRequest,
  updateMissionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Missions);
