import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Delete from "../../common/Dialogs/Delete";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import TestDetail from "./TestDetail.js";
import GameResets from "../../common/Dialogs/GameResets";
import ViewListIcon from "@material-ui/icons/ViewList";
import {
  teamsRequest,
  usersRequest,
  testsRequest,
  skillsRequest,
  secondaryTeamsRequest,
  imagesRequest,
} from "../../../../../actions/generalActions";
import {
  getTestsRequest,
  deleteTestRequest,
} from "../../../../../actions/testsActions";
import AddQuestionsDialog from "../../common/Dialogs/AddQuestions";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import TestDialog from "../../common/Dialogs/Test";
import "toastr/build/toastr.min.css";
import "./tests.scss";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  columnTable: {
    padding: "1px !important",
  },

  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Tests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTestDeatails: false,
      page: 0,
      rowsPerPage: 5,
      testID: null,
    };

    this.handleCloseTestDetails = this.handleCloseTestDetails.bind(this);
  }

  componentDidMount() {
    if (!this.props.generalReducer.tests) {
      this.props.testsRequest();
    }

    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }

    if (!this.props.generalReducer.images) {
      this.props.imagesRequest();
    }

    if (!this.props.generalReducer.skills) {
      this.props.skillsRequest();
    }

    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.testsRequest({ number: value + 1});
  }

  async deleteTest(id) {
    let status;
    await this.props.deleteTestRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.trivia-panel-message-success-delete-test",
          "Trivia removed successfully"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "menu.trivia-panel-message-error-delete-test-one",
          "This trivia is being used and cannot be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.trivia-panel-message-error-delete-test-two",
          "An error occurred while deleting the trivia"
        )
      );
    }
  }

  handleOpenTestDetails(id){
    this.setState({
      openTestDeatails: true,
      testID: id,
    })
  }

  handleCloseTestDetails(){
    this.setState({
      openTestDeatails: false,
      testID: null,
    })
  }

  render() {
    const { rowsPerPage, page, openTestDeatails, testID } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.testsReducer.tests_charging ||
            this.props.generalReducer.teams_charging ||
            this.props.generalReducer.secondary_teams_charging ||
            this.props.generalReducer.users_charging ||
            this.props.generalReducer.tests_charging ||
            this.props.generalReducer.skills_charging ||
            this.props.generalReducer.images_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {
            !this.props.generalReducer.teams_charging &&
            !this.props.generalReducer.secondary_teams_charging &&
            !this.props.generalReducer.users_charging &&
            !this.props.generalReducer.tests_charging &&
            !this.props.generalReducer.skills_charging &&
            !this.props.generalReducer.images_charging &&
            (this.props.generalReducer.tests &&
            this.props.generalReducer.tests.content.length > 0 ? (
              <Grid container spacing={1}>
                {this.props.generalReducer.teams &&
                  this.props.generalReducer.secondary_teams &&
                  this.props.generalReducer.users &&
                  this.props.generalReducer.images && (
                    <TestDialog
                      rowTeams={this.props.generalReducer.teams.content}
                      rowSecondaryTeams={
                        this.props.generalReducer.secondary_teams.content
                      }
                      rowsUsers={this.props.generalReducer.users.content}
                      firstImage={
                        this.props.generalReducer.images.content[0].url
                      }
                    />
                  )}

                <Link className="link-custom" to="/test/question">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-button-trivia-question",
                      "Trivia questions"
                    )}
                  </Button>
                </Link>

                <Link className="link-custom" to="/test/analytic">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-button-analitycs-responses",
                      "Resp. analytical info"
                    )}
                  </Button>
                </Link>

                <Link className="link-custom" to="/reset/test">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("menu.rigth-reset-trivia", "Reset trivia")}
                  </Button>
                </Link>

                <Link className="link-custom" to="/test/consolidated">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-button-consolidated-responses",
                      "Resp. consolidated info"
                    )}
                  </Button>
                </Link>

                <Link className="link-custom" to="/test/skills">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("skills-report.label", "Skills Report")}
                  </Button>
                </Link>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="rowTable" align="left">
                          {t("admin.user-panel-user-dialog-input-name", "Name")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("creation-date.label", "Creation date")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("expiration-date.label", "Expiration date")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("hide-date.label", "Hide date")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("guests", "Guests")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("trivia-completed-user-qty", "Participantes")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("guests-inhouse", "Guests InHouse")}
                        </TableCell>
                        
                        <TableCell className="rowTable" align="left">
                          {t("trivia-completed-user-inhouse-qty", "Participantes InHouse")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("guests-bpo", "Guests BPO")}
                        </TableCell>

                        <TableCell className="rowTable" align="left">
                          {t("trivia-completed-user-bpo-qty", "Participantes BPO")}
                        </TableCell>

                        {/*<TableCell className="rowTable" align="left">
                          {t("menu.trivia-panel-dialog-add-test-select-questions", "Preguntas")}
                        </TableCell>*/}

                        <TableCell className="rowTable" align="left">
                          {t("admin.user-panel-table-state", "State")}
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.generalReducer.tests.content
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                              onClick={() => this.handleOpenTestDetails(row.id)}
                            >
                              {row.name != null && row.name.length > 30
                                ? row.name.substring(0, 30) + "..."
                                : row.name}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.dateFrom,
                                es
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.dateTo,
                                es
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {row.hideOnDate
                                ? GeneralFunctions.formatNewDate(
                                    row.hideOnDate,
                                    es
                                  )
                                : "N/A"}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedCount ? row.invitedCount : 0}
                            </TableCell>
                            
                            <TableCell align="left">
                              {row.invitedCount ? row.approvedScores : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedInhouseCount ? row.invitedInhouseCount : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedCount ? row.approvedScoresInHouse : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedOutsourceCount ? row.invitedOutsourceCount : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.invitedCount ? row.approvedScoresOutsource : 0}
                            </TableCell>

                            <TableCell align="left">
                              {row.isActive === true &&
                                t(
                                  "admin.header-dropdown-view-conditions-table-active-state",
                                  "Active"
                                )}
                              {row.isActive !== true &&
                                t(
                                  "menu.trivia-panel-table-inactive",
                                  "Inactive"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              <div style={{display: "flex", justifyContent: "space-between"}}>
                                {this.props.generalReducer.skills && (
                                  <AddQuestionsDialog
                                    id={row.id}
                                    rowsSkills={
                                      this.props.generalReducer.skills.content
                                    }
                                  />
                                )}

                                {this.props.generalReducer.teams &&
                                  this.props.generalReducer.secondary_teams &&
                                  this.props.generalReducer.users &&
                                  this.props.generalReducer.images && (
                                    <TestDialog
                                      {...row}
                                      rowTeams={
                                        this.props.generalReducer.teams.content
                                      }
                                      rowSecondaryTeams={
                                        this.props.generalReducer.secondary_teams
                                          .content
                                      }
                                      firstImage={
                                        this.props.generalReducer.images
                                          .content[0].url
                                      }
                                      rowsUsers={
                                        this.props.generalReducer.users.content
                                      }
                                      typeModal="modalEdit"
                                    />
                                )}

                                <Delete
                                  title={t(
                                    "menu.trivia-panel-table-actions-delete-question-title",
                                    "Trivia management"
                                  )}
                                  deleteMetric={() => this.deleteTest(row.id)}
                                />

                                <GameResets game_id={row.id} gameType={"trivia"}/>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.generalReducer.tests.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    {this.props.generalReducer.teams &&
                      this.props.generalReducer.secondary_teams &&
                      this.props.generalReducer.users && (
                        <TestDialog
                          rowTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          getTests={() => this.getTests(page, true)}
                        />
                      )}

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
          <TestDetail
            title={t("menu.metric-panel-dialog-show-detail", "Show details")}
            open={openTestDeatails}
            handleClose={this.handleCloseTestDetails}
            testID={testID}
            //data={missionState.data}
          />
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, testsReducer }) => ({
  generalReducer,
  testsReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  testsRequest,
  skillsRequest,
  secondaryTeamsRequest,
  imagesRequest,
  getTestsRequest,
  deleteTestRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(Tests)));
